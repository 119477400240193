.job-search-msg {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 1.5rem!important;
}

.btn-search-alert {
  max-width: 164px;
}

.search-alert-title {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}

.search-notfound-modal-body {
  text-align: center;
  padding: 27px 68px 50px 68px;
}

@media screen and (max-width: 425px) {
  .search-alert-title {
    text-align: center;
  }
}
