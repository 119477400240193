@import 'global.css';
.usermanagementcontainer{
    margin: auto;
}
.registrationscreen{
    /* background-color: #817f7f; */
    margin-top: var(--user-managemnt-margin-top);
}
.loginscreen{
    /* background-color: #3d3c3c; */
    margin-top: var(--user-managemnt-margin-top);
    margin-bottom: var(--user-managemnt-margin-top);
}
.formcontainer{
    width: 40%;
    margin: auto;
    background-color: aliceblue;
    border: 1px solid var(--primary-color);
    border-radius: .3rem;
    outline: 0;
    color: var(--forms-text-color);
    padding: var(--forms-padding);
}
.inputfield{
    width: 70% !important;
    margin: auto;
    margin-top: 20px;
}
.forgotpassword{
    color: var(--primary-color);
    cursor: pointer;
}
.rememberme{
    color: gray;
    margin-top: 20px;
}
.formbuttonsmall{
    width: var(--forms-button-width);
    border: 1px solid gray !important;

}
.buttongroupsmall{
    width: 70%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buttongroupbig{
    width: 90%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.email-confirmation{
    display: flex;
    flex-direction: column;
    margin: auto;
}
.button-in-link{
    width: 100%;
    border: none;
    border-radius: 0px;
}
