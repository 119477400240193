body {
  counter-reset: section;
}

.work-job-desc-center-img {
  display: none;
}

.work-jobseeker-description {
  background: var(--primary-light);
  box-shadow: 0px 0px 42px -5px rgba(196, 196, 196, 0.4);
  border-radius: 14px;
  position: relative;
}

.work-third-main-container {
  background-image: url("../../Assets/Images/footer_backround.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% 23%;
}

.work-desc-col {
  margin-bottom: 10px;
}

.work-desc-main-container {
  max-width: 561px;
  width: auto;
  height: 516px;
  background: var(--primary-light);
  box-shadow: 0px 0px 42px -5px rgb(196 196 196 / 40%);
  border-radius: 14px;
  padding: 60px;
  margin: auto 20px;
}

.right-work-desc-container {
  margin-top: 74px;
}

.work-card-title {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: var(--primary-dark);
  margin: 21px auto 17px auto;
}

.work-card-detail {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary-grey);
}

.work-img {
  width: 72px;
  height: 72px;
}

.work-desc-details {
  align-items: center;
  padding: 100px 100px;
}

.work-third-desc-details {
  align-items: center;
  padding: 51px 100px;
}

.work-jobseek-desc-title {
  margin-bottom: 20px;
}

.align-items-center .lorem-img {
  text-align: right;
}

.work-job-desc-title {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 45px;
  color: var(--primary-dark);
  max-width: 90%;
}

.work-job-desc-details {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary-grey);
  max-width: 90%;
}

.work-fourth-desc-details {
  align-items: center;
  padding: 130px 100px;
}

.second-elem-container {
  margin-bottom: 0px;
}

.third-elem-container {
  margin-bottom: 0px;
}

.work-second-section {
  padding: 135px 0px 57px 0px;
}

.work-top-main-container {
  background-size: 100% 85%;
}

@media screen and (max-width: 1024px) {
  .work-top-main-container {
    background-size: 100% 85%;
  }

  .work-desc-main-container {
    padding: 40px;
  }
}

@media screen and (max-width: 1198px) and (min-width: 1021px) {
  .work-desc-main-container {
    padding: 40px;
  }
}

@media screen and (max-width: 1022px) and (min-width: 767px) {
  .work-top-main-container {
    background-size: 100% 81%;
  }

  .work-desc-main-container {
    padding: 28px;
  }
}

@media screen and (max-width: 990px) and (min-width: 767px) {
  .work-top-main-container {
    background-size: 100% 25%;
  }

  .work-header-img {
    text-align: center !important;
  }

  .work-header-img img {
    width: 368px;
    height: 245px;
  }

  .work-third-main-container {
    background-size: 100% 10%;
  }

  .work-desc-main-container {
    margin: auto;
  }
}

@media (max-width: 768px) {
  .work-job-desc-left-img {
    display: none;
  }
  .work-job-desc-center-img {
    display: block;
    text-align: center;
  }

  .work-job-desc-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
  }

  .work-job-desc-details {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
  }
  .work-desc-details {
    padding: 51px 28px;
  }

  .work-fourth-desc-details {
    align-items: center;
    padding: 45px 28px 80px 28px;
  }

  .second-elem-container {
    margin-bottom: 68px;
  }

  .fourth-job-center-img {
    margin: 30px 0px;
  }

  .work-second-section {
    padding: 82px 0px 57px 0px;
  }

  .work-top-main-container {
    /* background-size: 100% 45%; */
    background-size: 100% 45%, 100% 96%;
  }

  .work-second-section .lorem-img {
    width: auto;
    text-align: center;
    margin-top: 5px;
  }

  .job-inner-img {
    display: none;
  }

  .work-third-desc-details {
    padding: 0px 28px 36px 28px;
  }

  .third-elem-container {
    margin-bottom: 42px;
  }

  .work-desc-main-container {
    width: auto;
    height: 529px;
    padding: 28px;
    margin: auto 0px;
  }

  .right-work-desc-container {
    margin-top: 74px;
  }

  .work-img {
    width: 57px;
    height: 57px;
  }

  .work-card-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 40px;
    margin: 10px auto;
  }

  .work-card-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
  }

  .work-desc-col {
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .job-application-inner {
    margin-top: 28px;
  }

  .work-job-desc-title {
    text-align: left !important;
  }

  .work-job-desc-details {
    text-align: left !important;
  }

  .work-top-main-container {
    background-size: 100% 43%, 100%, 96%;
  }

  .work-third-main-container {
    background-image: url("../../Assets/Images/mobile_footer_background.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% 10%;
  }
}

@media (max-width: 767px) {
  .multiple-btn .section-left-round-btn + .section-left-round-btn {
    margin-left: 15px;
  }

  .secondsection-left-title {
    font-size: 24px;
    line-height: 30px;
    color: var(--primary-light);
    padding-left: 50px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 766px) and (min-width: 424px) {
  .work-top-main-container {
    background-size: 100% 21%, 100%, 96%;
  }
}

@media screen and (max-width: 425px) {
  .job-inner-img {
    display: none;
  }

  .work-top-main-container {
    background-position: center top, top bottom;
    background-size: 100% 22%, 100%, 94%;
  }

  .job-application-inner {
    margin-top: 28px;
  }

  .work-desc-main-container {
    margin: 0;
  }

  .work-card-title {
    margin: 18px auto 7px auto;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .job-inner-img {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .work-top-main-container {
    background-size: 100% 23%;
  }

  .work-desc-main-container {
    padding: 11px;
  }
}

@media screen and (max-width: 1463px) and (min-width: 720px) {
}
@media screen and (max-width: 1024px) and (min-width: 720px) {
}
