/* sidebar-menu */
.search-block {
  padding: 42px 26px 100px 26px;
  background-color: #ffffff;
}
.search-section .dropdown-toggle::after {
  color: var(--primary-deep-dark) !important;
  margin: auto 0;
  content: none;
}
.search-section [data-dropdown] > select.dropdown-display {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.down-arrow {
  position: absolute;
  top: 23px;
  right: 18px;
}

.sidebar-search-container {
  position: sticky;
  bottom: 0;
  width: 100%;
}

.sidebar-search-container-top {
  transition: top 1s ease 0s;
  top: 13.5%;
}

.sidebar-search-container-reduce-top {
  transition: top 1s ease 0s;
  top: 4%;
}

.sidebar-search-block .form-label {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: var(--primary-deep-dark);
}

.state-search-dropdown .select-miles-container {
  padding: 3px 30px 0px 8px !important;
}

.search-state-input {
  padding: 26px 19px !important;
  margin: 0;
  width: 100%;
  border: none;
  width: 100%;
}

.search-state-input::placeholder {
  color: var(--dark-grayish) !important;
}

.sidebar-search-block .form-control::placeholder {
  opacity: 1; /* Firefox */
  font-size: 16px !important;
  font-family: var(--primary-font-family);
}

.select-search-dropdown .dropdown-menu a {
  padding-left: 26px;
}

.state-search-dropdown .dropdown-toggle {
  width: 100%;
  padding: 0;
}

.search-state-input:focus {
  border: none;
  box-shadow: none;
}

.label-colon {
  display: none;
}

.search-error-container {
  margin-top: 28%;
}

.search-input-error {
  margin-top: 20px;
}

.job-desc-block b {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: var(--primary-deep-dark);
  font-family: var(--primary-font-family);
  font-style: normal;
  letter-spacing: 0.003em;
}

.job-desc-block,
.job-desc-block div,
.job-desc-block ul li,
.job-desc-block i {
  font-family: var(--primary-font-family);
  font-size: 16px;
  line-height: 26px;
  color: var(--dark-grayish);
  letter-spacing: 0.003em;
  font-style: normal;
  margin-top: 6px;
}

.job-desc-block ul{
  padding-left: 18px;
}

.job-desc-block p {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 5px;
  font-style: normal;
}

.search-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 999999;
}

.sidebar-search-block .form-control {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 26px 29px;
  color: var(--dark-grayish);
  transition: none;
  background-color: var(--secondary-color-dark-gray);
  border-radius: 5px;
}

.job-type-title {
  padding: 0px 28px 0px 35px;
}

.fullscreen-icon {
  cursor: pointer;
}

.sidebar-search-block input::placeholder {
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-deep-dark);
}
.sidebar-search-block .dropdown button {
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-deep-dark);
}

.sidebar-search-block .dropdown-menu a {
  border-left: 12px solid transparent;
}

.sidebar-search-block .dropdown-menu a:hover {
  border-left: 4px solid transparent;
  background: var(--secondary-color-sky);
}

.sidebar-search-block .dropdown-menu {
  width: 100%;
  transform: translate3d(0px, 52px, 0px);
}

.joblist-sections {
  padding: 0px 64px;
  background: #E0ECFF; 
}

.select-search-dropdown .dropdown-menu {
  max-height: 155px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 14rem;
  width: 100%;
}

.select-search-dropdown .dropdown-menu a {
  border-left: 4px solid transparent;
}

.invalid-input {
  border: 2px solid var(--primary-danger);
}
.valid-input {
  border: none;
}
button.btn.btn-primary.contact-btn:hover {
  background-color: var(--primary-sky);
}
.select-search-dropdown {
  /* padding: 4px 0px; */
  /* background-color: var(--secondary-color-dark-gray); */
  border-radius: 5px;
  cursor: pointer;
}

.qualification-section-block li::marker {
  color: var(--primary-sky);
  height: 8px;
  width: 8px;
}
.qualification-section-block li {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  color: var(--primary-deep-dark);
  line-height: 26px;
}
.search-application-type {
  margin: 43px 0px;
  padding-top: 32px;
  padding-bottom: 16px;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 4%);
  border-radius: 6px;
  border: none;
}

.search-job-desc {
  max-height: 205px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.search-application-type .card-header {
  background-color: var(--primary-light);
  border-bottom: 2px solid #f1f1f1;
}
.text-brown {
  color: var(--primary-deep-dark);
}
.job-type {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  padding-right: 18px;
  margin-bottom: 0px!important;
}
.job-type-subtitle {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  padding-bottom: 0px!important;
  margin-top: 10px;
}

.bottom-pagination .page-link {
  border: 0;
}

.bottom-pagination .page-item:first-child .page-link {
  margin-right: 14px;
}

.bottom-pagination .sr-only {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: var(--primary-deep-dark);
}

.restorrent-block {
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
}
.search-application-type .card-img {
  height: 25px;
  width: 25px;
}
.pagination-data .sr-only {
  position: relative;
}
.pagination-data li a span:first-child {
  display: none;
}
.pagination-data .page-link {
  background-color: transparent;
  color: var(--primary-deep-dark);
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
}

.back-edit-filter {
  cursor: pointer;
}

.back-filter-icon img {
  fill: #ffffff;
}

.job-type-title-float {
  float: left;
}

.expand-icon-container {
  padding-top: 18px;
  padding-right: 40px;
}

@media (max-width: 1024px) {
  .sidebar-search-block .form-label {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: var(--primary-deep-dark);
  }

  .select-search-dropdown .dropdown-menu a {
    border-left: 6px solid transparent;
  }

  .state-search-dropdown .dropdown-menu a {
    padding-left: 19px;
  }
}

@media (max-width: 991.98px) {
  .joblist-sections {
    padding: 0px 17px;
  }
  .card-header {
    padding: 0.25rem 1.25rem !important;
  }
  .sidebar-search-block .form-label {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: var(--primary-deep-dark);
  }

  .search-application-type {
    margin: 13px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #bdbdbd;
    border-radius: 6px;
  }
  .job-description {
    padding: 65px 38px 0;
  }
  .job-type {
    font-size: 16px;
    line-height: 10px;
    font-weight: 600;
  }
  .restorrent-block {
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
  }
  .qualification-section-block li {
    font-weight: 400;
    line-height: 23px;
    font-size: 17px;
  }

  .lg-search-result {
    display: none;
  }

  .search-application-type .card-header {
    border-bottom: solid transparent;
  }
  .joblist-sections {
    padding: 0px 39px;
  }
  .edit-filter {
    font-size: 16px;
    line-height: 16px;
    margin: auto 0;
  }
  .edit-filter-block {
    padding: 25px 38px;
    min-height: calc(100vh - 206px);
    /* height: 100vh; */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* z-index: 11; */
  }
  .edit-filter-block::-webkit-scrollbar-thumb {
    background: #b8b8b8;
    border-radius: 21px;
  }
  .edit-filter-block::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .job-type-title {
    padding: 0;
  }
  .job-type-subtitle {
    font-size: 14px;
    line-height: 20px;
    padding-top: 10px;
  }
  .job-type {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px!important;
  }
  .apply-btn-data {
    width: auto;
  }
  .restaurant-manager {
    border-bottom: 1px solid #dddddd;
  }
  .job-summary-data {
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
  }
  .job-summary-inner {
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding: 15px 4px;
  }
  .primary-block {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
  .primary-section-block li {
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    padding: 6px 0px;
    color: var(--primary-deep-dark);
  }
  .primary-section-block li::marker {
    color: var(--primary-sky);
    height: 8px;
    width: 8px;
  }

  .back-edit-filter {
    margin: 5px 0px 25px 3px;
    cursor: pointer;
  }

  .search-res-title {
    background-color: var(--primary-light);
  }

  .fs-apply-btn,
  .fs-apply-btn:focus {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 29px !important;
    border-radius: 4px;
  }

  .edit-filter-block::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  .edit-filter-block {
    overflow-x: hidden;
  }

  .search-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 999999;
  }

  .search-responsive-loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .select-search-dropdown .dropdown-item {
    font-size: 14px;
    line-height: 24px;
  }

  .sidebar-search-container {
    transition: none;
    top: auto;
    position: inherit;
    width: auto;
  }
}
@media (max-width: 767.98px) {
  .joblist-sections {
    padding: 64px 40px;
  }

  .search-button-css {
    /* text-align: center; */
    margin-bottom: 10px!important;
    border: none;
  }

  .search-block {
    padding: 104px 33px 30px;
  }

  .sidebar-search-block .form-control {
    font-size: 14px;
    padding: 26px 26px;
  }

  .sidebar-search-block .form-control::placeholder {
    font-size: 14px;
  }

  .label-colon {
    display: block;
  }

  .back-edit-filter {
    margin: 25px 0px 25px 3px;
  }

  .job-desc-block p,
  .job-desc-block b,
  .job-desc-block,
  .job-desc-block div,
  .job-desc-block ul li,
  .job-desc-block i {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-deep-dark);
  }

  .job-desc-block b {
    font-weight: 500;
  }

}

@media (max-width: 575.98px) {
}
@media (max-width: 375px) {
  .job-type {
    font-size: 14px;
    line-height: 20px;
  }
}
