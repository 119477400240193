.bg-dark-blue {
  background: #102938;
}

.footer-section li {
  list-style-type: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.footer-section ul {
  padding-inline-start: 32px !important;
}

.text-grey {
  color: var(--primary-light);
  opacity: 0.64;
}

.footer-section {
  padding: 30px 0px 8px 0px;
  font-weight: 400;
  font-family: poppins;
}

.page-wrapper {
  margin-top: 0px;
}

.contact-block {
  width: 100px;
}

.text-cursor {
  cursor: pointer;
}

.text-footer-link a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.txt-bottom-last-right {
  margin-right: 12px;
}

@media screen and (max-width: 425px) {
  .footer-container {
    padding: 0;
  }

  .footer-col {
    padding-left: 0px;
  }
}
