.loadingparent {
    position: fixed; /* Fixed position */
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    z-index: 10000; /* High enough to be on top */
}

