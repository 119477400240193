.d-search-job {
  display: block;
}

.m-search-job {
  display: none;
}

@media (max-width: 992px) {
  .d-search-job {
    display: none;
  }

  .m-search-job {
    display: block;
  }
}
