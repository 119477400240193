.input-from-container {
  width: 87%;
  margin: 0 auto;
}

.account-term-container {
  width: 82%;
  margin: 0px auto 63px 38px;
  text-align: left;
}

@media (min-width: 426px) and (max-width: 462px) {
  .account-conf-modal-body {
    padding: 22px 10px 50px 10px;
  }
}

@media screen and (max-width: 425px) {
  .input-from-container {
    width: 100%;
    margin: 0 auto;
  }

  .account-term-container {
    width: 100%;
    margin: 15px auto 38px auto;
  }

  .accountconf-title {
    font-weight: 600;
  }
}
