.pagebodyitem{
    width: 90%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.rowitem{
    width: 40%;
}