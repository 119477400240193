.terms-main-container {
  background-size: 100% 20%;
}

.terms-sec-main-container {
  background-size: 100% 4%;
}

@media (max-width: 768px) {
  .terms-main-container {
    background-size: 100% 6%;
  }

  .terms-sec-main-container {
    background-size: 100% 2%;
  }
}

@media screen and (max-width: 425px) {
  .terms-main-container {
    background-size: 100% 3%;
  }
  .terms-sec-main-container {
    background-size: 100% 1%;
  }
}
