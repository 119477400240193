.btn-resend-email {
  padding: 5px 44px;
  margin: 5px;
  color: var(--primary-blue);
  background-color: var(--primary-light);
  border: 2px solid var(--primary-bg) !important;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
  box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
}

.btn-close-confirm {
  padding: 5px 44px;
  margin: 5px;
  color: white;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-bg) !important;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  width: 55%;
  border-radius: 4px;
  line-height: 24px;
  font-family: var(--secondary-font-family);
  box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
}

.login-modal .btn-resend-email,
.btn-close-confirm {
  width: 100%;
  max-width: 214px;
  height: 50px;
}

.email-confirm-title {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: var(--large-font-size);
  line-height: 35px;
  margin-bottom: 40px;
  color: var(--primary-dark);
}

.btn-confirm-container {
  display: flex;
  justify-content: space-around;
}

.email-cnfrm-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
  margin-bottom: 40px;
  color: var(--primary-dark);
}

.lbl-resend-email {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #487b03;
  margin-bottom: 28px;
}

.homepage-iframe {
  height: 100vh;
  border: none;
}

@media screen and (max-width: 522px) and (min-width: 426px) {
  .btn-resend-email,
  .btn-close-confirm {
    padding: 5px 5px;
  }
}

@media screen and (max-width: 425px) {
  .email-confirm-title {
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .email-cnfrm-subtitle,
  .lbl-resend-email {
    font-family: var(--secondary-font-family);
  }

  .email-cnfrm-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }

  .lbl-resend-email {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .btn-resend-email,
  .btn-close-confirm {
    font-family: var(--secondary-font-family);
    font-weight: var(--medium-font-weight);
    font-size: 16px;
    line-height: 23px;
    padding: 5px 0px;
  }

  .login-modal .btn-resend-email,
  .btn-close-confirm {
    width: 148px;
    height: 44px;
    font-size: 18px;
  }
}
@media screen and (max-width: 345px) {
  .login-modal .btn-resend-email,
  .btn-close-confirm {
    font-size: 15px;
  }
}
