.privacy-description {
  background: var(--primary-light);
  box-shadow: 0px 0px 42px -5px rgb(196 196 196 / 40%);
  border-radius: 14px;
  padding: 72px 65px;
}

.privacy-title-row {
  margin-bottom: 39px;
}

.privacy-second-section {
  padding: 78px 0px 120px 0px;
}

.privacy-second-main-container {
  background-image: url("../../Assets/Images/footer_backround.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% 5%;
}

.privacy-desc a {
  color: var(--primary-blue);
  word-break: break-word;
}

.m-bold-text {
  color: var(--primary-grey);
}

.privacy-capitalize {
  text-transform: uppercase;
}

.privacy-title {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  color: var(--primary-grey);
  font-family: var(--primary-font-family);
}

.policy-title {
  margin: 10px 0px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.policy-sub-title {
  margin: 10px 0px;
  font-weight: bolder;
  font-size: 16px !important;
  line-height: 25px !important;
}

.privacy-desc {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: var(--primary-grey);
}

.privacy-desc p {
  color: var(--primary-grey);
}

.privacy-sub-desc li::marker {
  color: var(--primary-grey);
  font-size: 24px;
  border-radius: 50%;
}

.privacy-main-container {
  background-image: url("../../Assets/Images/header_large_back.jpg");
  background-repeat: no-repeat;
  background-size: 100% 25%;
}

.privacy-modal-title {
  justify-content: center;
  text-align: center;
}

@media (max-width: 768px) {
  .privacy-description {
    padding: 45px 29px;
  }

  .privacy-title {
    font-size: 22px;
    line-height: 24px;
  }

  .privacy-desc {
    font-size: 14px;
    line-height: 25px;
  }

  .policy-title {
    font-size: 14px !important;
    line-height: 24px;
  }

  .policy-sub-title {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .privacy-desc p {
    color: var(--primary-grey);
    font-size: 14px;
  }

  .privacy-title-row {
    margin-bottom: 29px;
  }

  .privacy-main-container {
    background-image: url("../../Assets/Images/mobile_header_background.png"),
      url("../../Assets/Images/mobile-background-second-section.png");
    background-repeat: no-repeat, no-repeat;
    background-position: center top, top bottom;
    background-size: 100% 5%, 100% 97%;
  }

  .privacy-second-section {
    padding: 0px 0px 120px 0px;
  }

  .privacy-second-main-container {
    background-image: url("../../Assets/Images/mobile_footer_background.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% 4%;
  }
}

@media screen and (max-width: 425px) {
  .privacy-main-container {
    background-repeat: no-repeat, no-repeat;
    background-position: center top, top bottom;
    background-size: 100% 5%, 100% 97%;
  }

  .privacy-second-main-container {
    background-image: url("../../Assets/Images/mobile_footer_background.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% 2%;
  }

  .privacy-second-section {
    padding: 0px 0px 120px 0px;
  }
}
