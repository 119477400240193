.reset-password-back {
  text-align: left;
  cursor: pointer;
}

.reset-pass-body {
  padding: 0px 98px 56px 98px;
}

.btn-reset-pass {
  margin-top: 66px;
}

@media screen and (max-width: 425px) {
  .reset-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .reset-pass-body .credentials-details {
    padding-bottom: 30px;
  }

  .btn-reset-pass {
    margin-top: 34px;
  }

  .reset-password-back {
    margin-top: 10px;
    margin-right: 10px;
  }
}

.deftle-modal-title {
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.deftle-error-color {
  color: red; 
}
.defle-modal-text {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-top: 16px;
}

.deftle-modal-button {
  text-align: center;
  padding: 10px 44px;
  margin: 5px;
  color: white;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  line-height: 24px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
  box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
}

.deftle-modal-button-enabled {
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-blue) !important;
}
.deftle-modal-button-disabled {
  background-color: var(--gray-light);
  border: 1px solid var(--gray-light);
  cursor: not-allowed;
}

.qrapply-checkbox-wrapper {
  text-align: left; /* Ensure left alignment of children */
  padding-left: 20px; 
}

.deftle-modal-checkbox .custom-control-input {
    margin: 0;
    padding: 0;
}

.deftle-modal-checkbox .custom-control-input ~ .custom-control-label::before {
  border: 4px solid var(--primary-blue);
}

.deftle-modal-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--primary-blue);
}

.deftle-modal-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25)
}
.deftle-modal-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
.deftle-modal-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: var(--gray-light);
}


