/* .modal.fade .modal-dialog {
  right: -2020px !important;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;
} */

.auth-modal.right.fade.show .modal-dialog {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.left.fade.show .modal-dialog {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.close-right.fade:not(.show, .close-left) .modal-dialog {
  /* right: -100vw;
  top: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  transition: opacity 0.3s linear, right 0.3s ease-out !important; */
  transform: translate3d(100vw, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.close-left.fade:not(.show) .modal-dialog {
  transform: translate3d(-100vw, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.right .modal-dialog {
  /* -webkit-transform: translate3d(25%, 0, 0); */
  transform: translate3d(100vw, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.left .modal-dialog {
  /* -webkit-transform: translate3d(25%, 0, 0); */
  transform: translate3d(-100vw, 0, 0);
  transition: transform 0.5s ease;
}

/* .modal-backdrop {
  display: none !important;
} */

.modal-header {
  display: inline;
  color: #9b5670;
  font-family: var(--primary-font-family) !important;
  font-size: 12px;
  font-weight: 700;
  border-bottom: none !important;
}

/* .modal-dialog {
  max-width: 310px !important;
  top: 77px;
} */

.auth-modal-back {
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
}

.modal-content {
  background: var(--primary-light) !important;
  box-shadow: 0px 6px 60px -5px rgba(117, 117, 117, 0.26) !important;
  border: 1px solid rgb(0 0 0 / 0%);
  border-radius: 0px;
}


.lbl-auth-or {
  z-index: 999999;
  margin: 20px 0px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
}

.btn-auth-back {
  display: flex;
}

.btn-signup {
  padding: 13px 18px 13px 18px;
  margin: 0;
  color: white;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  border-radius: 4px;
  /* width: 80%; */
  font-family: var(--secondary-font-family);
  text-align: left;
  height: 50px;
}

.bth-auth {
  box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
}

.btn-auth-social {
  padding: 13px 18px 13px 18px;
  margin: 11px 0 0 0;
  color: white;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  font-style: normal;
  border-radius: 4px;
  /* width: 80%; */
  text-align: left;
  font-family: var(--secondary-font-family);
  height: 50px;
}

.btn-auth-google,
.btn-auth-google:hover,
.btn-auth-google:active,
.btn-auth-google:focus {
  color: #757575;
}

.btn-auth-social.btn-dark {
  background-color: var(--primary-deep-dark);
  border-color: var(--primary-deep-dark);
}
.btn-auth-social.btn-primary {
  background-color: #1877f2;
  border-color: #1877f2;
}
.btn-signin {
  background-color: var(--primary-light);
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}

.btn-signin:hover,
.btn-signin:active,
.btn-signin:focus {
  color: var(--primary-blue);
}

.btn-pwa-singin {
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-bg);
}

.btn-signup img {
  width: 26px;
  height: 26px;
  /* margin: 4px; */
  background-color: #ffffff00;

  margin-right: 20px;
}

.btn-auth-social img {
  width: 26px;
  height: 26px;
  margin-right: 18px;
}
.btn-light {
  color: #757575;
}

.label-back {
  font-family: var(--primary-font-family);
  font-weight: var(--medium-font-weight);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-left: 7px;
}

.auth-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  left: -1%;
  bottom: 0%;
}

.fed-auth-loader {
  width: 100%;
  height: 100%;
  left: -1%;
  bottom: 0%;
}

.btn-pwd-shadow {
  box-shadow: 0px 0px 6px 2px rgb(169 156 156 / 31%) !important;
}

.btn-signin-shadow {
  box-shadow: 0px 0px 6px 2px rgb(169 156 156 / 28%) !important;
}

.fed-placeholder-modal .modal-content {
  width: 73%;
  margin: auto;
}

.fed-type-title {
  margin-bottom: 70px !important;
}

.btn-auth-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body {
  text-align: center;
  padding: 50px 98px 56px 98px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .modal-dialog {
    top: 0px !important;
  }
}

@media (max-width: 767px) {
  .modal-body {
    padding: 22px 30px 50px 30px;
  }
  .modal-header {
    padding: 22px 0 0 32px;
  }

  .modal-content {
    background: var(--primary-light) !important;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.21) !important;
  }

  .btn-signup {
    font-size: 18px;
    line-height: 24px;
  }

  .bth-auth {
    box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
  }

  .lbl-auth-or {
    font-size: 14px;
    line-height: 18px;
  }
  .btn-auth-social {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 425px) {
  .auth-modal-cancel {
    justify-content: space-between;
  }

  .auth-action-body {
    padding: 0px 30px 40px 30px !important;
  }

  .lbl-auth-or {
    margin: 15px 0px;
  }
  .modal-body {
    padding: 0 14px 50px 14px;
  }
  .modal-header {
    padding: 26px !important;
  }
}
