.homePageLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  bottom: 10%;
  right: 0%;
}

.recaptcha-container {
  display: none;
}

.modal-header {
  display: inline;
  color: #3a3a3a;
  font-family: var(--primary-font-family) !important;
  font-size: 12px;
  font-weight: 700;
  padding: 26px 44px;
  border-bottom: none !important;
}
.modal-dialog {
  max-width: 525px !important;
  /* top: 60px; */
}

.btn-pwa-signUp {
  padding: 5px 44px;
  margin: 5px;
  color: var(--primary-light);
  font-style: normal;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-blue);
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  line-height: 24px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--primary-font-family);
  box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
}

.btn-signup {
  width: 100%;
}

.btn-auth-social {
  width: 100%;
}
.credentials-details {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: 25px;
  line-height: 25px;
  padding: 10px;
  color: #3a3a3a;
}
.email {
  color: #9b5670;
  padding: 7px;
  font-size: 11px;
}

label,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
  display: inline-block;
  vertical-align: middle;
}

.form-floating {
  position: relative;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: #333;
}

label:hover span::before,
label:hover span::before {
  -moz-box-shadow: 0 0 2px #ccc;
  -webkit-box-shadow: 0 0 2px #ccc;
  box-shadow: 0 0 2px #ccc;
}

/* label:hover span,
label:hover span {
  color: var(--primary-deep-dark);
} */

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 12px;
  height: 12px;
  margin: 0 4px 0 0;
  border: solid 1px #a8a8a8;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
  background: #f6f6f6;
}

input[type="checkbox"]:checked + span::before {
  color: #666;
}

input[type="checkbox"]:disabled + span {
  cursor: default;

  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 18px;
  line-height: 18px;
}

input[class="blue"] + span::before {
  border: solid 2px var(--primary-blue);
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin: 0 9px 0 0;
}
input[class="blue"]:checked + span::before {
  color: var(--primary-blue);
}
.password-change {
  color: var(--primary-blue);
  font-weight: var(--medium-font-weight);
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.lbl-keep-login {
  font-family: var(--primary-font-family) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 20px;
  color: var(--primary-dark) !important;
  display: flex;
}

.sigin-third-container {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 14px;
  align-items: center;
  text-align: center;
}

.login-btn {
  text-align: center;
}
.error-msg p {
  text-align: center;
  color: var(--primary-danger);
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.login-modal .modal-dialog {
  max-width: 784px !important;
}
.login-modal .credentials-details {
  padding-bottom: 50px;
}
.login-modal .btn-pwa-signUp {
  width: 100%;
  max-width: 234px;
  height: 50px;
}

.keep-login {
  text-align: left;
}

.top-forgot-container {
  display: none;
}

@media (max-width: 767px) {
  .modal-header {
    padding: 20px 44px 15px !important;
  }
  .credentials-details {
    font-size: 18px;
  }
  .login-modal .credentials-details {
    padding-bottom: 39px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 425px) {
  .signin-modal-body {
    padding: 0px 30px 38px 30px !important;
  }

  .signin-modal-body .credentials-details {
    padding-bottom: 11px;
  }

  .auth-floating-input {
    height: 50px;
  }

  .credentials-details {
    font-family: var(--secondary-font-family);
    font-weight: 500;
    line-height: 24px;
    color: #3a3a3a;
  }

  .sigin-third-container {
    align-items: center;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  .lbl-keep-login {
    font-family: var(--secondary-font-family) !important;
    font-size: 14px !important;
    line-height: 18px;
  }

  .lbl-keep-login label:hover span,
  .lbl-keep-login label label:hover span {
    color: var(--primary-dark);
  }

  .password-change {
    margin-top: 6px;
    font-family: var(--secondary-font-family);
    letter-spacing: 0.3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  .top-forgot-container {
    display: block;
    text-align: right;
    margin-bottom: 11px;
  }

  .bottom-forgot {
    display: none;
  }

  .btn-pwa-signUp {
    font-size: 18px;
    line-height: 24px;
    font-family: var(--secondary-font-family);
    font-weight: 500;
  }

  .login-modal .btn-pwa-signUp {
    max-width: 214px;
  }

  .error-msg p {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.3px;
  }
}

@media screen and (max-width: 375px) {
  /* .password-change {
    margin: 23px 0px;
  } */
}
