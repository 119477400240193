.email-verify-title {
  font-weight: normal !important;
  margin-bottom: 61px !important;
  font-size: var(--medium-font-size) !important;
}

@media screen and (max-width: 425px) {
  .btn-verified-close {
    max-width: 148px !important;
    height: 44px !important;
  }
}
