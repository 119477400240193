.lbl-search-type {
  font-family: var(--primary-font-family);
  color: var(--primary-deep-dark);
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
}

.logo-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-left: 10px;
}

.search-inputbox {
  display: block;
  width: 100%;
  padding: 26px 29px;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-grayish) !important;
  background-color: var(--primary-light);
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-family: var(--primary-font-family);
}
.sidebar-search-block .form-control::placeholder {
  color: var(--primary-grey);
}

.form-placedata input {
  background-color: var(--secondary-color-dark-gray);
  border-radius: 5px;
  height: 55px;
}

.form-placedata input::placeholder {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
}

.state-search-inputbox {
  border: none;
  background-color: var(--secondary-color-dark-gray);
  padding: 27px 19px;
}

.state-search-inputbox:focus {
  border: none !important;
  box-shadow: none;
  background-color: var(--secondary-color-dark-gray);
}

.state-search-inputbox::placeholder {
  color: var(--dark-grayish);
  opacity: 1; /* Firefox */
}

.miles-dropdown {
  background: var(--secondary-color-dark-gray);
  border: none;
  /* height    : 55px; */
  border-radius: 5px;
  cursor: pointer;
  padding: 15px 30px;
  border-radius: 5px;
}

.miles-dropdown .dropdown-menu {
  max-height: 155px;
  overflow-y: auto;
}

.select-miles-dropdown {
  justify-content: space-between;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-miles-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 14px 30px;
  background-color: var(--secondary-color-dark-gray);
  white-space: nowrap;
  border-radius: 5px;
}

.select-dropdown-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--primary-font-family);
  color: var(--dark-grayish);
  margin: 0;
}

.select-search-dropdown .dropdown-item:focus,
.select-search-dropdown .dropdown-item:hover {
  background: var(--secondary-color-sky) !important;
}

.btn-search {
  background: #FFFFFF26;
  border-radius: 6px;
  width: 100%;
  padding: 11px 0;
  border: 1px solid #ffffff;
}
#cityDropdownArrow, #stateDropdownArrow {
  background-color: transparent !important;
  border: none !important;
}

.btn-search span {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--primary-light) !important;
}

.btn-search-container {
  margin-top: 50px;
  padding: 0 15px;
}

.location-container {
  margin-top: 40px;
}

.search-type-container {
  padding-top: 45px;
}

.hospitality-section-block {
  padding: 89px 0px 122px;
}

section.our-specialist {
  background: rgba(129, 178, 20, 0.16);
  padding-top: 60px;
  padding-bottom: 60px;
}

.servicetype-list {
  margin: 0;
  list-style: none;
  padding: 0;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: var(--dark-grayish);
}

.hospitality-home-section {
  margin-top: 153px;
  margin-bottom: 121px;
}

.bottom-map-container {
  padding: 0px 50px;
}

.service-title {
  padding-right: 47px;
}

.job-block-inner {
  font-weight: 700;
  font-size: 40px;
  color: var(--dark-grayish);
  line-height: 65px;
  max-width: 684px;
}

.miles-dropdown .dropdown-menu.show {
  width: 100%;
  transform: translate3d(0px, 42px, 0px) !important;
}

.miles-dropdown .dropdown-menu a:hover {
  border-left: 6px solid transparent;
  background: var(--secondary-color-sky);
}

.image-hos-col-data {
  padding-left: 58px;
}

.job-hospitality-main-section {
  padding: 0px 183px 0 137px;
}

.search-mobileview-poster {
  min-height: 350px;
  background-color: #000;
}

.city-search-dropdown .dropdown-menu {
  overflow-y: hidden !important;
}

.city-search-dropdown .search-list-dropdown {
  height: auto !important;
}

.search-list-dropdown {
  overflow-x: hidden;
  max-height: 155px;
}

.search-list-dropdown .search-list-item:hover {
  background: var(--secondary-color-sky);
}

.search-list-dropdown .search-list-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: unset;
  outline: none;
}

.btn.focus,
.btn:focus {
  box-shadow: unset;
  outline: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .second-first-col {
    margin-left: 16.666667%;
  }

  .text-second-section-right-side-img {
    margin-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .location-container {
    margin-top: 10px;
  }

  .second-section-right-img {
    max-height: 800px;
  }
}

@media (max-width: 1440px) {
  .service-title {
    padding-right: 20px;
  }

  .job-block-inner {
    font-size: 40px;
    line-height: 50px;
  }

  .job-hospitality-main-section {
    padding: 0px 100px 0 100px;
  }
}

@media (max-width: 1200px) {
  .service-title {
    padding-right: 20px;
  }

  .job-block-inner {
    font-size: 32px;
    line-height: 45px;
  }

  .job-hospitality-main-section {
    padding: 0px 30px 0 30px;
  }
}

@media only screen and (max-width: 1391px) and (min-width: 1200px) {
  .lbl-search-type {
    font-size: 13px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1205px) {
  .Current-openings-block h2 {
    font-size: 34px;
    line-height: 50px;
  }
}

@media (max-width: 1024px) {
  .image-hos-col-data {
    padding-left: 5px;
  }

  .box-items {
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .search-job-mobile-container .btn-search {
    background-color: #3FA4CC;
    border-radius: 6px;
  }
  .header-secound-section {
    padding-bottom: 40px !important;
    padding-top: 60px;
  }
  .home-main-container {
    background-size: 100% 52% !important;
  }
  .search-job-mobile-container {
    margin-bottom: 35px;
  }
  .search-job-mobile-container .lbl-search-type {
    color: #2B2E39;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  .consectetus {
    margin-bottom: 30px;
  }
  .secondsection-left-title {
    font-size: 27px;
    font-weight: 700;
    line-height: 36px;
    padding-left: 100px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {

  .desktop-view-data {
    display: none;
  }

  .text-second-section-right-side-img {
    display: none;
  }
}

@media (max-width: 770px) {
  .header-secound-section {
    padding-bottom: 40px !important;
    padding-top: 120px;
  }

  .head-desktop {
    display: none;
  }

  .select-dropdown-title {
    font-size: 14px;
  }

  .job-hospitality-main-section {
    padding: 0px;
  }

  .btn-search {
    padding: 10px 0;
  }
}

.lbl-search-type {
  font-family: var(--primary-font-family);
  color: var(--primary-light);
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
}

.form-placedata input {
  background-color: var(--secondary-color-dark-gray);
  border-radius: 5px;
  height: 55px;
}

.form-placedata input::placeholder {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
}

.miles-dropdown {
  background: var(--secondary-color-dark-gray);
  border: none;
  /* height    : 55px; */
  border-radius: 5px;
  cursor: pointer;
  padding: 15px 30px;
  border-radius: 5px;
}

.miles-dropdown .dropdown-menu {
  max-height: 155px;
  overflow-y: auto;
}

.select-miles-dropdown {
  justify-content: space-between;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-miles-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 14px 30px;
  background-color: var(--secondary-color-dark-gray);
  white-space: nowrap;
  border-radius: 5px;
}

.select-dropdown-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--primary-font-family);
  color: var(--dark-grayish);
  margin: 0;
}


.btn-search span {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--primary-light) !important;
}

.btn-search-container {
  margin-top: 30px;
}

/* .location-container {
  margin-top: 40px;
} */

.search-type-container {
  padding-top: 45px;
}

.hospitality-section-block {
  padding: 89px 0px 122px;
}

section.our-specialist {
  background: rgba(129, 178, 20, 0.16);
  padding-top: 60px;
  padding-bottom: 60px;
}

.servicetype-list {
  margin: 0;
  list-style: none;
  padding: 0;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: var(--dark-grayish);
}

.hospitality-home-section {
  margin-top: 153px;
  margin-bottom: 121px;
}

.bottom-map-container {
  padding: 0px 50px;
}

.service-title {
  padding-right: 47px;
}

.job-block-inner {
  font-weight: 700;
  font-size: 40px;
  color: var(--dark-grayish);
  line-height: 65px;
  max-width: 684px;
}

.miles-dropdown .dropdown-menu.show {
  width: 100%;
  transform: translate3d(0px, 42px, 0px) !important;
}

.miles-dropdown .dropdown-menu a {
  border-left: 6px solid transparent;
}

.miles-dropdown .dropdown-menu a:hover {
  border-left: 6px solid transparent;
  background: var(--secondary-color-sky);
}

.image-hos-col-data {
  padding-left: 58px;
}

.job-hospitality-main-section {
  padding: 0px 183px 0 137px;
}

.lbl-search-type {
  margin-bottom: 17px;
}

@media (max-width: 1440px) {
  .service-title {
    padding-right: 20px;
  }

  .job-block-inner {
    font-size: 40px;
    line-height: 50px;
  }

  .job-hospitality-main-section {
    padding: 0px 100px 0 100px;
  }
}

@media (max-width: 1200px) {
  .service-title {
    padding-right: 20px;
  }

  .job-block-inner {
    font-size: 32px;
    line-height: 45px;
  }

  .job-hospitality-main-section {
    padding: 0px 30px 0 30px;
  }

  .lbl-search-type {
    margin-bottom: 5px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1391px) and (min-width: 1200px) {
  .lbl-search-type {
    font-size: 13px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1205px) {
  .Current-openings-block h2 {
    font-size: 34px;
    line-height: 50px;
  }
}

@media (max-width: 1024px) {
  .image-hos-col-data {
    padding-left: 5px;
  }

  .box-items {
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .hospitality-section-block {
    padding: 18px 0px 57px;
  }

  .search-type-container {
    padding: 45px 19px 0px;
  }

  .location-container {
    padding: 7px 15px 0px;
    margin: 0;
  }

  .btn-search-container {
    margin-top: 21px;
    padding: 0 15px;
  }

  .home-section-main-block {
    padding: 0px 19px;
  }

  .hospitality-home-section {
    margin-top: 38px;
    margin-bottom: 38px;
  }

  .job-block-inner {
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-grayish);
    line-height: 36px;
  }

  .miles-dropdown {
    padding: 8px 28px;
    height: 54px;
  }

  .image-hos-col-data {
    padding-left: 0px;
  }

  .img-bg-desktop {
    display: none;
  }

  .search-inputbox {
    font-size: 14px;
    padding: 26px 26px;
  }

  .state-search-inputbox {
    padding: 26px 17px !important;
  }

  .search-inputbox::placeholder {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .second-section-right-img {
    display: none;
  }
}

@media only screen and (max-width: 768px) and (min-width: 320px) {
  .desktop-view-data {
    display: none;
  }

  .text-second-section-right-side-img {
    display: none;
  }
}

@media (max-width: 770px) {
  .head-desktop {
    display: none;
  }

  .select-dropdown-title {
    font-size: 14px;
  }

  .job-hospitality-main-section {
    padding: 0px;
  }

  .btn-search {
    padding: 10px 0;
  }
}

@media (max-width: 425px) {
  .box-items {
    max-width: 348px;
    margin-left: 2px;
    padding: 40px 41px 68px;
  }
}
