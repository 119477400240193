.btn-pwa-forgot-container {
  margin-top: 46px;
}

.forgot-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
  color: var(--primary-dark);
}

.success-msg {
  color: #487b03;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 21px;
}

.forgot-title {
  padding-bottom: 42px !important;
}

.forgot-input {
  margin-top: 38px;
}

.forgot-pass-body {
  padding: 0px 98px 56px 98px;
}

@media screen and (max-width: 425px) {
  .forgot-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-family: "Roboto";
  }

  .forgot-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .success-msg {
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }

  .forgot-back-container {
    text-align: center;
    display: flex;
    /* padding: 10px 5px; */
  }
}
