.notfound-container {
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .img-notfound {
    width: 100%;
  }
}
