.first-choose {
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 41px 0;
  color: var(--primary-dark);
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}

.public-profile-modal-class .btn-job-select {
  width: 208px;
  height: 50px;
}

.btn-job-select {
  padding: 0px 44px;
  margin: 5px;
  color: white;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  line-height: 24px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
}

.btn-job-disable {
  background-color: var(--gray-light) !important;
  border: 1px solid var(--gray-light) !important;
}

.btn-job-enable {
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-blue) !important;
  box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
}

.apply-terms {
  color: #3a3a3a;
  font-size: 14px;
  display: flex;
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 30px 0px 0px;
  align-items: center;
}

.application-type-lbl {
  padding-left: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.public-profile-modal-class input[class="blue"] + span::before {
  border: solid 3px var(--primary-blue);
  background-color: transparent;
  width: 24px;
  height: 24px;
}

.public-profile-modal-class input[class="blue"] {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.public-profile-modal-class .modal-dialog {
  max-width: 870px !important;
}
.public-profile-modal-class .credentials-details {
  padding-bottom: 50px;
}
.public-profile-modal-class .btn-pwa-signUp {
  width: 208px;
  height: 50px;
}

@media (max-width: 767px) {
  .public-profile-modal-class .credentials-details {
    padding-bottom: 39px;
    padding-left: 0;
    padding-right: 0;
  }

  .public-profile-modal-class .btn-pwa-signUp {
    width: 158px;
    max-width: 208px;
  }

  .joboption-header div {
    padding: 11px 0px !important;
  }
  .job-option-title {
    margin: 0px 6px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--secondary-font-family);
  }

  .jobopt-cancel {
    margin-top: 4px;
  }

  .job-modal-step {
    align-items: flex-start !important;
  }

  .choose-option-body {
    padding: 0px 0px 25px 0px;
  }

  .apply-terms {
    color: #3a3a3a;
    font-size: 14px;
    display: flex;
    text-align: initial;
    margin: auto;
    width: 100%;
    padding: 19px 0px 0px 0px;
    font-family: "Roboto";
    align-items: flex-start;
  }

  .first-choose {
    padding: 0px 0px 17px 0px;
    width: 90%;
    padding: 0px 0px 15px 0px !important;
  }
}

@media screen and (max-width: 425px) {
  .public-profile-modal-class .btn-job-select {
    width: 158px;
    height: 44px;
  }

  .public-profile-modal-class .btn-pwa-signUp {
    height: 44px;
  }
  .application-type-lbl {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }

  .first-choose {
    font-size: 14px;
    line-height: 18px;
  }

  .job-option-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .job-btn-sel-container {
    margin-top: 12px;
  }

  .btn-job-select {
    padding: 0px 44px;
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .view-position {
    width: auto;
  }
  .modal-body .back-join {
    font-size: 14px;
    font-family: var(--secondary-font-family);
  }

  .app-modal-back {
    padding-left: 19px !important;
  }

  .application-type-lbl {
    padding-left: 5px;
  }

  .choose-option-title-container {
    text-align: center;
  }

  .choose-option-title-container .back-join {
    font-size: 18px;
  }

  .apply-terms .option-checkbox {
    padding-top: 5px;
  }

  
}
