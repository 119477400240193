.error-msg-apply-job p {
  font-size: 14px;
  text-align: center;
  color: var(--primary-danger);
  font-weight: 600;
  font-family: var(--primary-font-family);
  font-style: normal;
  line-height: 20px;
}

.submit-modal {
  padding: 41px 0px 35px;
}

.submit-modal .exp-submitstyle {
  width: 84%;
  text-align: left;
  margin: auto;
}

.lbl-email-container {
  text-align: left;
}

.email-input-disabled input:disabled {
  color: var(--primary-dark);
  opacity: 0.5;
  -webkit-text-fill-color: var(--primary-dark);
}

.lbl-email-note {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #808080;
}

.txt-lbl-email {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 0px;
  color: #3a3a3a;
}
.txt-emailaddress {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: 16px;
  line-height: 0px;
  margin-left: 23px;
}

.email-notify-border {
  border: 2px solid var(--primary-blue);
}

.dropdown-miles {
  color: var(--primary-dark);
  background-color: var(--primary-light);
  border-color: var(--primary-blue);
  border-width: 2px;
  margin: 0;
  padding: 4px 29px 4px 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  border-radius: none;
}

.dropdown-miles:after {
  display: inline-block;
  margin-left: 10px;
  content: "";
  border-top: 10px solid #c4c4c4;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
  position: absolute;
  left: auto;
  top: 10px;
}

.lbl-mile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  margin-top: 1%;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-miles {
  background-color: var(--primary-light);
  border-color: var(--primary-blue);
  color: var(--primary-dark);
  border-width: 2px;
}

.lbl-allow-email {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: var(--primary-dark) !important;
  font-family: var(--primary-font-family) !important;
}


.dropdown-miles-container {
  margin-left: 34px;
}

.zipcode-floating-container {
  width: 246px;
}

.lbl-zipcode-instruction {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-grey);
}

.lbl-title-hint {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-grey);
}

.setting-zipcode-inst-margin {
  margin-left: 30%;
}

.lbl-zipcode-inst-margin {
  margin-left: 50%;
}

.dropdown-miles-container .dropdown-menu {
  min-width: 40px;
}

.dropdown-miles-container .dropdown-menu .dropdown-item {
  padding: 0.25rem 16px;
}

.lbl-role-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.txt-lbl-role {
  flex-shrink: 0;
}

.lbl-role-inner-container {
  width: auto;
  overflow: visible;
}

.role-input-container {
  margin-left: 14px;
}



.chk-notify-container {
  display: flex;
}

.role-input-container {
  width: 100%;
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 425px) {
  .lbl-allow-email {
    font-family: var(--secondary-font-family) !important;
  }

  .submit-modal {
    padding: 25px 0px 18px 0px;
  }


  .info-detail-body {
    padding: 0px 20px 22px 20px !important;
  }

  .lbl-allow-email {
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .lbl-email-container {
    display: none;
  }

  .email-input-container {
    display: block;
  }

  .email-input-container .form-floating-input input {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #3a3a3a;
  }

  .lbl-mile {
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }

  .dropdown-miles-container {
    margin-left: 32px;
  }

  .zipcode-floating-container {
    width: auto;
    max-width: 168px;
  }
  .dropdown-miles {
    padding: 4px 27px 4px 8px;
  }

  .dropdown-miles:after {
    border-top: 10px solid #c4c4c4;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }

  .zipcode-floating-container .form-floating {
    height: 50px;
  }

  .lbl-zipcode-instruction {
    font-family: var(--secondary-font-family);
    margin-top: 2%;
    font-size: 12px;
    line-height: 12px;
  }

  .setting-zipcode-inst-margin {
    margin-left: 45%;
  }

  .lbl-zipcode-inst-margin {
    margin-left: 48%;
  }
  .lbl-email-note {
    display: none;
  }

  .email-input-container .form-floating-input input {
    border-bottom: 2px solid var(--primary-sky);
  }

  .lbl-title-hint {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    font-family: var(--secondary-font-family);
    color: var(--dark-grey);
    text-align: center;
  }

  .mobile-role-input-container {
    display: block;
  }

  .chk-notify-container {
    justify-content: center;
    align-items: center;
  }

  .email-input-disabled input {
    border-bottom-color: var(--dark-grey) !important;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .exp-submitstyle {
    width: auto;
  }

  .submit-modal .exp-submitstyle {
    width: 100%;
  }
}
