.search-qualification-block::marker {
  color: #b8b8b8;
  height: 8px;
  width: 8px;
}
.search-qualification-block li {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  color: var(--dark-grayish);
  line-height: 26px;
}
.midnigth {
  color: var(--dark-grayish);
}

.apply-btn-style,
.apply-btn-style:focus {
  margin: auto 0;
  padding: 10px 53px !important;
  margin-right: 37px;
}

.searchexpand-data {
  padding: 26px 38px;
  height: auto;
  overflow-y: auto;
}

.sidebar-searchexpand-data {
  background-color: #ffffff;
  padding: 26px 38px;
  position: sticky;
  top: 0%;
  height: 100vh;
  overflow-y: auto;
}

.search-job-expand {
  padding: 0px 38px;
  height: 192vh;
  overflow-y: auto;
}

.searchexapand-block-card {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}
.searchexapand-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 10px;
  padding-top: 11px;
}

.lg-search-result::-webkit-scrollbar {
  display: none;
}

.searchexpand-data::-webkit-scrollbar {
  width: 14px;
}
.searchexpand-data::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.searchexpand-data::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 21px;
}

.selected-search-job {
  border: 1px solid var(--primary-light-green);
}

.searchexpand-data,
.searchexpand-sidebar-innercontainer .card-body {
  cursor: pointer;
}

.searchexpand-data-block::-webkit-scrollbar {
  display: none;
}
.searchexpand-data-block {
  padding: 10px 38px;
  height: auto;
  overflow-y: auto;
}

.search-expand-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 99vh;
  z-index: 999999;
}

.txt-edit-filter {
  font-weight: 500;
  padding-top: 2px;
  cursor: pointer;
}

.search-apply-btn-container {
  display: flex;
  align-items: center;
}

.left-panel-search-job:hover {
  border: 1px solid var(--primary-light-green);
}
