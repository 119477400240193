.skip-checkbox {
  display: flex;
  justify-content: center;
}
.skip-checkbox-style {
  padding: 10px;
  display: flex;
  align-items: center;
  padding-left: 43px;
  cursor: pointer;
}

.document-modal-body {
  padding: 0px 98px 28px 98px !important;
}

.resume-uploaded-modal-body {
  padding: 1rem !important;
}
.resume-uploaded-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #3A3A3A;
  margin-bottom: 30px;
}
.resume-uploaded-subtext {
  margin: 30px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3A3A3A;
}

.close-uploaded-resume {
  margin-top: 30px !important;
}

.from-radio-input {
  display: flex;
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 15px 0px 0px;
  cursor: pointer;
}
input[type="radio"] {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--primary-blue);
  margin: 0px 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary-blue);
}

input[type="radio"]:checked {
  border-color: var(--primary-blue);
}

.tstyle th {
  padding: 0.1rem 0.1rem;
  width: 100%;
  padding-left: 8px;
}

.tstyle tr:hover {
  cursor: pointer;
  /* background-color: #ede493; */
}

.selected-tr {
  background-color: #ede493;
}

.tstyle td {
  padding: 0.2rem 0.1rem;
  width: 100%;
  padding-left: 8px;
  color: var(--primary-dark);
}

.table-hedar {
  color: white !important;
  background-color: var(--primary-bg) !important;
}

.uploaded-doc-table thead tr th {
  border: 2px solid #3a3a3a;
}

.uploaded-doc-table tbody tr td {
  border: 2px solid #3a3a3a;
  border-top: none;
  vertical-align: middle;
}

.table-hedar th {
  padding: 0.2rem 0.1rem;
}

.tstyle .t-whitespace {
  overflow: hidden;
  height: 80px;
  white-space: nowrap;
}
.resume-alldata {
  text-align: initial;
  margin: auto;
  width: 100%;
  padding: 14px 0px 30px 0px;
  color: #3a3a3a;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  font-style: 16px;
}
.resume-alldata .upload-table {
  margin-left: auto;
  width: 100%;
  margin-top: 14px;
  border: 2px solid #3a3a3a;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
}

.resume-alldata .upload-table thead tr th {
  padding-left: 8px;
}
.job-modal-step {
  justify-content: space-between;
  align-items: center;
}

.video-modal-body {
  padding: 0px 75px 28px 75px;
}

.lbl-video-control {
  margin-top: 10px;
  margin-left: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.lbl-play-control-disable {
  color: var(--primary-grey);
}

.recording-icon {
  height: 25px;
  width: 25px;
}

.col-play-controls {
  margin: 0;
  padding: 0;
}

.lbl-rec-counter {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: 25px;
  line-height: 35px;
  margin-left: 3px;
}

.rec-counter-cotainer {
  margin-top: 28px;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

.video-player-container {
  width: 100%;
  height: auto;
  min-height: 201px;
  max-height: 201px;
}

.drag-input-message {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-cursor {
  cursor: pointer;
}

.step-style {
  color: var(--primary-dark);
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0.3px;
  font-style: normal;
}

.cancel-job {
  font-family: var(var(--primary-font-family));
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-right: 9px;
  color: #3a3a3a;
}

/* .back-icon {
  margin-bottom: 3px;
} */

.cancel-icon {
  margin-bottom: 2px;
}

.upd-new-doc {
  display: none;
}

/* .t-textarea {
  margin-left: auto;
  width: 86%;
} */

.upload-new-container {
  margin-left: auto;
  width: 86%;
}

.t-textarea .pink {
  font-size: 16px;
  color: var(--primary-dark);
}
.t-textarea .pink .b-style {
  font-weight: bold;
  color: var(--primary-blue);
}

.t-textarea .drop-mobile {
  display: none;
}

.drop-desktop {
  margin: 7px 0px;
  cursor: pointer;
}

.drag-area-input {
  width: 100%;
  height: auto;
  min-height: 252px;
  max-height: 252px;
  border: 2px solid #3a3a3a;
}

.video-area-input {
  width: 100%;
  height: auto;
  min-height: 205px;
  max-height: 205px;
  border: 2px solid #3a3a3a;
}

.error-msg-apply-job p {
  font-size: 14px;
  text-align: center;
  color: var(--primary-danger);
  font-weight: 600;
  font-family: var(--primary-font-family);
  font-style: normal;
  line-height: 20px;
}

.my-fromresume {
  display: flex;
  padding-top: 21px;
  justify-content: space-between;
  align-items: center;
}
.my-fromresume .my-placeholder {
  font-size: 14px;
  color: var(--primary-dark);
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  width: 42%;
}
.collapse-radio {
  cursor: pointer;
}

.upld-resume-container {
  margin-left: 82px;
}

.form-floating-container {
  width: 320px;
}
.btn-clear {
  font-size: 16px;
  color: var(--primary-dark);
  text-align: right;
  margin-top: 12px;
  cursor: pointer;
}

.remove-doc-icon img {
  width: 16px;
  height: 15px;
}

.doc-action-icon {
  padding: 0px 5px !important;
}

.uploadDocLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  bottom: 0%;
  top: 0;
  right: 0;
  left: 5%;
}

.updNewDocLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  bottom: 6%;
  right: 0;
  left: 0%;
}

.lbl-upddocs {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.video-back-filter {
  background-color: var(--primary-deep-dark);
}

.tips-container {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  margin-top: 16px;
}

.model-body-text {
  display: flex;
  padding: 30px;
  text-align: center;
}

.model-cancel-job {
  cursor: pointer;
}

.disabled-player-control {
  pointer-events: none;
}

/* 
@media screen and (max-width: 1024px) and (min-width: 720px) {
  .doc-upd-modal {
    top: 45px !important;
  }
}
*/
@media screen and (max-width: 425px) {
  .skip-checkbox {
    padding: 10px 0px;
  }

  .skip-checkbox-style {
    padding: 0px;
    padding-left: 10px;
  }
  .resume-alldata {
    font-family: var(--secondary-font-family);
    padding: 18px 10px 0px 10px;
    width: auto;
  }

  .drop-mobile {
    display: none;
  }

  .upload-device {
    font-size: 14px;
    color: var(--primary-blue);
    font-weight: bold;
    line-height: 20px;
  }

  .drag-area-input {
    margin-left: 37px;
    width: auto;
  }

  .video-area-input {
    margin-left: 7px;
    width: auto;
  }

  .upld-resume-container {
    margin-left: 37px;
  }

  .my-fromresume {
    padding-top: 10px;
    align-items: center;
    margin-left: 37px;
  }

  .upd-doc-name {
    height: 33px !important;
  }

  .upd-doc-name input {
    height: 33px;
    font-size: 16px;
    padding: 7px 0 8px 5px;
  }

  .upd-doc-name span {
    display: none;
  }

  .upd-new-doc {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin: 0px 26px;
    margin-bottom: 7px;
  }

  .single-drag-area {
    margin-left: 0px;
  }

  .single-doc-name {
    margin: 0px 25px;
  }

  /* 
  .doc-upd-modal {
    top: 5px !important;
  }*/

  .resume-alldata .upload-table {
    border: none;
  }

  .uploadDocLoader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999999;
    bottom: 8%;
    right: 0;
    left: 6%;
  }

  .updNewDocLoader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999999;
    bottom: 14%;
    right: 0;
    left: 0%;
  }

  .btn-clear {
    margin-left: 20px;
    margin-right: 20px;
  }

  .video-area-container {
    justify-content: center;
  }

  .rec-counter-cotainer {
    margin-top: 0px;
  }
  .video-player-container {
    min-height: 167px;
    max-height: 167px;
    object-fit: cover;
  }

  .video-area-input {
    min-height: 167px;
    max-height: 167px;
  }
}

@media screen and (max-width: 720px) {
  .upd-tbl-fixed tbody {
    overflow: auto;
    height: auto;
    max-height: 95px;
  }

  .upd-name {
    width: 234px;
  }
  .upd-view {
    padding-right: 19px !important;
  }
  .upd-remove {
    width: 28px;
  }
  .lbl-rec-counter {
    font-size: 22px;
  }
}

@media screen and (max-width: 425px) {
  .upd-new-doc-title {
    margin: 0px 24px;
  }

  .single-drag-area {
    margin: auto 20px;
  }

  .tips-container {
    font-size: 16px;
    line-height: 20px;
    font-family: var(--secondary-font-family);
    margin: 10px 28px 0px 34px;
  }

  .doc-upd-list {
    display: block;
  }

  .uploaded-doc-table {
    border-bottom: 2px solid #3a3a3a !important;
  }

  .uploaded-doc-table tbody tr:last-child td {
    border-bottom-style: none !important;
  }

  .upload-table tbody {
    max-height: 128px !important;
  }

  .fullscreen-video-position {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .lbl-upddocs {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
 
  .first-choose {
    padding: 27px 0;
  }

  .document-modal-body {
    padding: 0px 0px 27px 0px !important;
  }

  .video-modal-body {
    padding: 0px 0px 27px 0px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .t-textarea .pink .b-pdf {
    display: none;
  }

  .resume-alldata .upload-table {
    font-family: var(--secondary-font-family);
    padding: 27px 10px;
  }

  .t-textarea {
    width: 100%;
  }

  .t-textarea .drop-desktop {
    display: none;
  }

  .drop-mobile {
    margin: 7px 0px;
  }

  .from-radio-input {
    width: 100%;
  }

  .my-fromresume .my-placeholder {
    width: 96%;
  }

  .label-back {
    font-family: var(--secondary-font-family);
  }

  .cancel-job {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 416px) and (min-width: 374px) {
  .upd-name {
    width: 224px;
  }

  .uploaded-doc-table tbody {
    max-height: 99px;
  }
}

@media screen and (max-width: 375px) and (min-width: 359px) {
  .upd-name {
    width: 184px;
  }

  .uploaded-doc-table tbody {
    max-height: 98px;
  }
}

@media screen and (max-width: 360px) and (min-width: 319px) {
  .upd-name {
    width: 169px;
  }
}

@media screen and (max-width: 320px) {
  .label-back {
    font-family: var(--secondary-font-family);
  }

  .cancel-job {
    font-family: var(--secondary-font-family);
  }
  .upd-name {
    width: 129px;
  }
}

@media screen and (max-width: 1463px) and (min-width: 720px) {
  .t-textarea .pink img {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .model-body-text {
    padding: 15px 0;
  }
}
