.modal-body .back-join {
  font-weight: var(--medium-font-weight);
  font-style: normal;
  font-size: 25px;
  line-height: 25px;
  color: #3a3a3a;
}
.modal-body .switch-count {
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a3a;
  cursor: pointer;
}
.modal-body .switch-count span {
  font-weight: 700;
  color: var(--primary-blue);
}

.desc-head-br {
  display: none;
}

.apply-auth-signup {
  border: 2px solid var(--primary-blue);
  color: var(--primary-blue);
  padding: 10px 36px;
}

.apply-auth-login {
  background-color: var(--primary-blue);
  padding: 10px 46px;
  color: var(--primary-light);
}

.apply-auth-btn-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.apply-auth-btn {
  height: 50px;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.view {
  padding-bottom: 10px;
}
.view-position {
  text-align: initial;
  margin: auto;
  width: 90%;
  padding: 59px 0 42px 0px;
}

.job-notfound-container {
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 59px 0 42px 0px;
}

.job-notfound-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
}

.btn-joe-con {
  width: auto;
}

.reauth-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-reauth-container {
  width: 342px;
  margin: 0 auto;
  margin-top: 30px;
}

.reauth-title {
  width: 350px;
  color: var(--primary-dark);
}

.reauth-input {
  width: 300px;
}

@media screen and (max-width: 425px) {
  .btn-reauth-container {
    width: 260px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .apply-auth-btn {
    padding: 7px 16px !important;
    height: 41px;
    font-weight: 500;
    width: 127px;
  }

  .job-notfound-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-family: var(--secondary-font-family);
  }

  .job-notfound-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .public-profile-modal-class .btn-pwa-signUp {
    height: 44px;
  }

  .desc-head-br {
    display: block;
  }

  .modal-body .switch-count {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
  }

  .reauth-input {
    margin-top: 20px;
  }

  .view-position {
    padding: 48px 0 42px 0px;
  }

  .modal-open .modal {
    position: fixed !important;
    width: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .reauth-title {
    font-size: 14px;
    line-height: 18px;
  }

  @supports (-webkit-touch-callout: none) {
    .scroll-modal {
      overflow: hidden !important;
    }

    @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      .scroll-modal {
        overflow-x: hidden !important;
        overflow-y: auto !important;
      }

      .scroll-modal .modal-dialog {
        margin-bottom: 50%;
      }
    }
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .view-position {
    width: auto;
  }

  .job-modal-body {
    padding: 22px 21px 50px 21px;
    font-family: "Roboto";
  }

  .job-modal-body .desc-title {
    font-size: 18px !important;
  }

  .reauth-container {
    display: block;
  }

  .reauth-title {
    width: 100%;
  }

  .reauth-input {
    width: 100%;
    margin-top: 20px;
  }
}
