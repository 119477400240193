.password-detalis, .password-detalis p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: var(--dark-grey);
  margin-bottom: 11px;
}

.accept-terms-container {
  text-align: left;
}

.accept-terms-condition {
  font-size: 16px;
  color: #3a3a3a !important;
  font-weight: 400 !important;
  user-select: none;
  line-height: 20px;
  font-family: var(--primary-font-family) !important;
}

.term-condition {
  font-size: 16px;
  color: var(--primary-blue);
  font-weight: var(--semi-bold-font-weight);
}
/* .check-box label:hover .term-condition {
  color: #9b5670;
} */
.create-account-modal .modal-dialog {
  max-width: 870px !important;
}
.create-account-modal .modal-body {
  padding: 0px 98px 84px 98px;
}
.create-account-modal .login-btn {
  margin-top: 60px;
}
.create-account-modal .btn-pwa-signUp {
  width: 100%;
  max-width: 208px;
  height: 50px;
}
.create-account-modal .credentials-details {
  padding-bottom: 50px;
}

.modal-open {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.term-condition label:hover span,
.term-condition label:hover span {
  color: var(--primary-blue) !important;
}

.txt-accept-terms-col {
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 0;
}

/* 
@media screen and (max-width: 1024px) and (min-width: 720px) {
  .create-account-modal {
    top: 45px !important;
  }
} */

@media (max-width: 767px) {
  .create-account-modal .modal-body {
    padding: 0 30px 50px 30px;
  }
  .create-account-modal .credentials-details {
    padding-bottom: 39px;
  }
  .create-account-modal .modal-header {
    padding: 22px 0 12px 32px;
  }
}

@media screen and (max-width: 425px) {
  .signup-back {
    float: left;
  }

  .signup-modal-title {
    padding: 0px 0px 24px 0px !important;
  }

  .signup-btn {
    margin-top: 18px !important;
  }

  .create-account-modal .modal-body {
    padding: 24px 30px 24px 30px;
  }

  .accept-terms {
    margin-left: 2px;
  }

  .check-box {
    margin-top: 0px;
  }

  .password-detalis,
  .password-detalis p {
    font-size: 12px;
    line-height: 14px;
    font-family: var(--secondary-font-family);
  }

  .accept-terms-condition {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3a3a3a !important;
    font-family: var(--secondary-font-family) !important;
  }

  .term-condition {
    font-size: 14px;
    line-height: 18px;
  }

  .txt-accept-terms-col {
    padding-top: 1px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 408px) {
  .terms-checkbox-col {
    padding-left: 0px;
  }

  .txt-accept-terms-col {
    padding-left: 0px;
  }

  .term-chkbox-row {
    margin: auto 0;
  }
}
