.list-items ul li {
  font-size: 18px;
  font-family: var(--primary-font-family);
  line-height: 27px;
  font-weight: 400;
  padding-bottom: 12px;
}

.nw-sec {
  border: unset !important;
}

.nw-brder {
  border-bottom: 15px solid var(--primary-light-green);
  padding-top: 35px;
}

ul.available-job-position-list li:before {
  content: "\2022";
  color: #f5c80e;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.dec {
  position: relative;
  height: 14.2em;
  margin: 0 0 1em 0;
  overflow: hidden;
}

.user-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  margin-left: 0;
  width: 169px;
  cursor: pointer;
}

.navbar-brand label {
  margin-bottom: 0;
}

.dropdown-toggle:hover,
.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-items:hover {
  font-weight: bold;
}

.dropdown-menu-container {
  margin-top: 18px;
  text-align: center;
  padding: 20px 0px !important;
  width: 250px;
  height: auto;
  right: 30px !important;
  left: unset;
  border-radius: 5px;
  box-shadow: 0px 4px 25px rgb(0 0 0 / 21%)
}

.dropdown-item-divider {
  border-bottom: 1px solid var(--primary-blue);
  width: 313px;
  height: 0px;
  margin: auto;
  padding: 0px;
}

.dropdown-items {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 45px;
  text-align: center;
  color: #3a3a3a;
  padding: 3px 0px !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  text-decoration: none;
  background-color: #e9ecef00;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--primary-deep-dark);
  text-decoration: none;
  background-color: #007bff00;
}

.user-icon,
.dropdown-user-icon {
  /* background-color: var(--secondary-blue); */
  background-color: var(--primary-bg);
  font-size: 20px;
  color: var(--primary-light) !important;
  /* margin: 8px; */
  margin-right: 8px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  display: flex;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.dropdown-toggle::after {
  display: none;
}

.item12 {
  background: #fdfdfd;
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding-bottom: 60px;
  margin-bottom: 40px;
}

section.joblist-sections-for-page {
  margin-top: 50px;
  /*margin-bottom: 50px;*/
}

p.heading-title {
  margin-bottom: 0px;
  background: var(--primary-bg);
  padding: 10px 100px;
  color: #ffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold;
}

p.location-manage {
  background: var(--primary-bg);
  padding: 0px 100px 10px;
  color: #ffff;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: var(--primary-light);
}

.dec {
  padding: 0px 100px 10px;
}

.learn-more a {
  float: right;
  background: #fdfdfd;
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 31px rgb(0 0 0 / 5%);
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 100px;
  font-weight: 700;
  font-family: var(--primary-font-family);
  color: var(--dark-grayish);
  text-decoration: none !important;
}

form.form-submit label {
  font-family: var(--primary-font-family);
  font-size: 18px;
}

.form-submit input {
  height: 70px !important;
  border: 2px solid rgba(43, 46, 57, 0.4);
  border-radius: 7px;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  margin-top: -22px;
}

.form-submit input::placeholder {
  font-family: var(--primary-font-family);
}

.spaces {
  padding-bottom: 15px;
}

.spacess {
  padding-bottom: 20px;
}

.accordion {
  max-width: 800px;
  margin: 0px auto;
}

.head-padding {
  padding: 15px 02px 21px;
}

/*search*/
section.body-part.search {
  background-image: linear-gradient(#ffffff00, #d6dec3);
}

.row.search-row {
  padding: 80px 0px 40px;
}

.search-row .row {
  padding-top: 10px;
}

.send-resume.text-center {
  margin: 0px 60px;
  padding: 25px;
  background: var(--primary-light);
  border-radius: 6px;
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 4px rgb(0 0 0 / 5%);
  opacity: 0.9;
}

.search-row label {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.search-row .form-control {
  border: none;
  border-bottom: 1px solid var(--primary-bg);
  background: #fdfdfd;
  border-radius: 4px;
}

.search-row .dropdown-toggle {
  background-color: #fdfdfd !important;
  border-radius: 4px;
  border: none;
  border-bottom: 1px solid var(--primary-bg);
  color: #162937;
}

.search-row .dropdown-toggle:hover {
  background-color: #fdfdfd !important;
  color: #162937 !important;
}

.search-row button.btn-primary.first-btn {
  background: var(--primary-bg) !important;
  border: none;
  border: none;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-right: 40px;
  padding-left: 40px;
}

.search-row button.btn.btn-primary.second-btn {
  background: #fdfdfd !important;
  border: none;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #b8b8b8;
}

.search-row button.btn.btn-primary.submit-btns {
  background: var(--primary-bg);
  border: none !important;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.search-row .form-control::placeholder {
  color: #acacac;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.search-row button.btn.btn-primary.dropdown-toggle {
  color: #4f4f4f;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: lowercase;
}

.search-row .send-resume p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.search-row .send-resume h3 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: var(--primary-deep-dark);
}

.dropdown-toggle::after {
  color: #5ba3c8 !important;
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 3.2s !important;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.map {
  box-shadow: 0px 0px 12px #00000085;
  border-radius: 0px !important;
  padding: 0px !important;
}

.close {
  display: none;
}

.navbar-toggler[aria-expanded="true"] .close {
  display: block;
}

.navbar-toggler[aria-expanded="false"] .close {
  display: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  display: none;
}

body span.navbar-toggler-icon {
  background-image: url("../../Assets/Images/menu.svg") !important;
  height: 24px;
  margin-top: -10px;
  margin-right: 10px;
}

.navbar-toggler .close {
  margin-right: 13px;
  margin-top: -7px;
}

*:focus {
  outline: 0 !important;
}

div#navbarNavAltMarkup a {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  padding-right: 50px;
}

div#navbarNavAltMarkup span {
  font-family: var(--primary-font-family);
  font-size: 18px;
  color: var(--very-dark-grayish);
  font-weight: 400;
  padding: 10px;
  padding-right: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.navbar-light .navbar-toggler {
  border: none;
}

body h2 {
  font-family: var(--primary-font-family);
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: var(--dark-grayish);
  line-height: 50px;
}

body h3 {
  color: var(--primary-bg);
  font-family: var(--primary-font-family);
  font-weight: bold;
}

body p {
  font-family: var(--primary-font-family);
  font-size: 18px;
  color: var(--dark-grayish);
}

.search-button-css,
.search-button-css:focus {
  background: var(--primary-bg);
  border-radius: 6px;
  color: var(--primary-light) !important;
  padding: 10px 14px !important;
  transition: all ease-in-out 0.5s;
  font-size: 18px;
  line-height: 27px;
}

a.nav-item.nav-link.disabled.search-button-css.search-button-navbar:hover {
  color: var(--primary-deep-dark) !important;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

a.nav-item.nav-link.disabled.search-button-css.search-button-navbar:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  color: var(--primary-light) !important;
  /*background: #0069d9;*/
}

.navbar-nav .active,
.navbar-nav .active a {
  color: var(--primary-bg) !important;
}

section.navbar-box-shdows {
  box-shadow: 0px 0px 60px rgba(172, 172, 172, 0.25);
}

.main-navbar-container {
  z-index: 1 !important;
  box-shadow: 0px 0px 60px rgb(0 0 0 / 11%) !important;
}

/* section.navbar-box-shdows a {
  font-size: 20px;
  color: var(--primary-deep-dark);
  font-weight: 500;
  padding: 10px;
  padding-right: 50px;
} */


.from-up-label {
  font-weight: var(--medium-font-weight);
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.auth-modal-back span,.reset-password-back span {
  display: flex;
}

.navbar-inner .navbar-nav a {
  font-size: 20px;
  color: var(--dark-grayish);
  font-weight: 500;
  padding: 0px 20px !important;
}

.navbar-inner .navbar-nav span {
  font-size: 20px;
  color: var(--dark-grayish);
  font-weight: 500;
  padding: 10px 20px !important;
  transition: all ease-in-out 0.5s;
}

.navbar-nav .nav-link:hover {
  color: var(--primary-bg) !important;
  transition: all ease-in-out 0.5s;
}

div#navbarNavAltMarkup .icon-style-call span:hover {
  color: #fff !important;
  background: #1b7a9f;
  transition: all ease-in-out 0.5s;
}

div#navbarNavAltMarkup span:hover {
  color: var(--primary-bg) !important;
  transition: all ease-in-out 0.5s;
  cursor: pointer;
}

.top-navbar {
  background-color: var(--primary-light);
}

.job-seekers {
  font-weight: 400;
  color: var(--very-dark-grayish);
  max-width: 58%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  font-size: 18px;
}

a.navbar-brand {
  font-weight: 600;
  margin-left: 10px;
}

.footer-section p a {
  color: var(--primary-light);
  text-decoration: none;
  opacity: 0.64;
}

.footer-section p a:hover {
  color: var(--primary-bg);
}

.footer-section .address p {
  color: var(--primary-light);
  opacity: 0.64;
}

.footer-section .copyright p {
  color: var(--primary-light);
  opacity: 0.64;
}

.nav-drop-menu .dropdown-menu a:hover {
  border-left: 12px solid var(--primary-light-green);
  background: var(--primary-light);
}

.nav-drop-menu .dropdown-menu a {
  border-left: 7px solid var(--primary-light);
}

/********hospital section **************/

section.Hospitality-section {
  margin-top: 60px;
  margin-bottom: 60px;
}

.hospitality-row .img-thumbnail {
  background: none;
  border: 0px;
  padding-left: 0px !important;
  padding: 0px;
  border-radius: 0px;
}

.contact-btn {
  font-family: var(--primary-font-family) !important;
  font-size: 18px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  height: 50px;
  line-height: 20px;
}

/**********lorem iopsum section ************/
.lorem-ipsun-row .img-thumbnail {
  border: none;
  padding: 0;
  border-radius: unset;
}

.image-lorem-col {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

section.lorem-ipsum-section {
  padding-top: 40px;
  padding-bottom: 60px;
}

section.our-specialist {
  background: rgba(129, 178, 20, 0.16);
  padding-top: 60px;
  padding-bottom: 60px;
  transition: all ease-in-out 0.5s;
}

.box-items {
  /* padding: 20px; */
  background: #ffff;
  margin-bottom: 30px;
  box-shadow: 0px 0px 106px -5px rgba(196, 196, 196, 0.4);
  border-top: 10px solid var(--primary-light);
  transition: all ease-in-out 0.5s;
  padding: 65px 40px 87px;
  /* max-width : 353px; */
}

.box-items-first {
  border-top: 10px solid var(--primary-light);
  transition: all ease-in-out 0.5s;
}

.box-items:hover {
  border-top: 10px solid var(--primary-light-green);
  transition: all ease-in-out 0.5s;
}

.navbar-nav .show .dropdown-toggle::after {
  transform: rotate(-180deg);
}

.height {
  margin-top: 80px;
}

.box-items img {
  margin-bottom: 31px;
}

.text-apron-right-side-img {
  padding: 0px !important;
}

/**********new page css start here ************/
.restaurant-first-section .img-thumbnail {
  border: none;
  padding: 0;
  margin-left: 15px;
  border-radius: 0px;
}

.row.restaurant-row {
  padding: 97px 0px;
  padding-top: 66px;
  padding-right: 0px;
  padding-bottom: 60px;
  padding-left: 0px;
}

.row.restaurant-row .btn-primary {
  color: var(--primary-light);
  background-color: var(--primary-bg);
  border-color: var(--primary-bg);
}

.row.restaurant-row .btn-primary:hover {
  color: var(--primary-light);
  background-color: #0069d9;
  border-color: #0062cc;
}

.postjob-accordion .faq-head h3 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
}

.postjob-accordion .faq-head h2 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: var(--dark-grayish);
  margin-bottom: 30px;
}

.postjob-accordion .card-header {
  border-bottom: none !important;
  background-color: var(--primary-light) !important;
}

.postjob-accordion .card {
  margin-bottom: 20px !important;
  padding: 10px;
  border-radius: 12px !important;
  border: 2px solid #c4c4c4 !important;
}

button.btn.btn-link.collapsed:before {
  font-family: "Poppins";
  content: "\f067";
  float: right;
  padding-top: 0px;
  color: var(--primary-bg);
}

button.btn.btn-link:before {
  font-family: "Poppins";
  content: "\f068";
  float: right;
  padding-top: 0px;
  color: var(--primary-bg);
}

.postjob-accordion button.btn.btn-link {
  width: 100%;
  text-align: left;
}

.postjob-accordion button.btn.btn-link.collapsed {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-bg);
  text-decoration: none;
}

.postjob-accordion .btn-link {
  font-weight: 400;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--primary-bg);
  white-space: normal;
}

.postjob-accordion .btn-link:hover {
  text-decoration: none;
  color: var(--primary-bg);
}

.postjob-accordion .btn-link:focus {
  text-decoration: none;
  color: var(--primary-bg);
}

.postjob-accordion .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0px 30px 50px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

button.btn.btn-primary.contact-formm {
  color: var(--primary-light);
  background-color: var(--primary-bg);
  border-color: var(--primary-bg);
}

button.btn.btn-primary.contact-formm:hover {
  color: var(--primary-light);
  background-color: #0069d9;
  border-color: #0062cc;
}

.col-sm-12.faq {
  padding: 0px !important;
  margin-top: 70px;
}

button.btn.btn-primary.contact-btn {
  background: var(--primary-bg);
  border: 1px solid var(--primary-bg);
}

button.btn.btn-primary.contact-btn:hover {
  background: #0069d9;
}

.text-apron-right-side-img {
  padding: 0px !important;
}

.mission-row {
  background: var(--dark-grayish);
  border-top: 15px solid var(--primary-light-green);
  display: block;
}

.about-img {
  padding-right: 0 !important;
}

/*about us page*/

.our-mission h2,
.our-mission p {
  color: var(--primary-light);
}

.our-mission-section {
  padding-top: 200px;
}

.getintouch-row {
  padding: 50px;
  box-shadow: 0px 4px 100px -5px rgb(196 196 196 / 40%);
  background: var(--primary-light);
  /* background-image: url("images/contact box.png"); */
  position: relative;
}

.getintouch-row:before {
  position: absolute;
  content: "";
  display: block;
  border-top: 15px solid var(--primary-light-green);
  width: 40%;
  left: 0;
  top: -16px;
}

.getintouch-row:after {
  position: absolute;
  content: "";
  display: block;
  border-left: 15px solid var(--primary-light-green);
  left: -10px;
  height: 78%;
  top: -16px;
  z-index: 9;
}

.getintouch-section {
  margin-top: 100px;
  margin-bottom: 0px;
}

button.btn.btn-primary.contact-btn.space {
  margin-bottom: 15px;
}

.submit-btn {
  margin-top: 33px;
  color: var(--primary-light);
  background: var(--primary-bg);
  border-radius: 6px;
  border: 1px solid var(--primary-bg);
  width: 164px;
  height: 50px;
}

.desktop-view .btn-space button.btn.btn-primary.dropdown-toggle {
  left: 10px;
  position: relative;
}

.active-menu {
  /* color: var(--primary-bg) !important; */
  font-weight: 700 !important;
}

.dec p,
.dec1 p {
  color: #acacac;
}

.dec ul li,
.dec1 ul li {
  color: #acacac;
}

p.short-description {
  color: var(--primary-deep-dark);
}

/********** pagination css ************/
div#pagination-container {
  margin: 0 auto;
  text-align: center;
  display: flex;
}

div#pagination-container ul {
  display: flex;
  margin: 0 auto;
}

div#pagination-container ul li {
  list-style: none;
}

div#pagination-container {
  padding-bottom: 50px;
}

div#pagination-container a.page-link.prev {
  background: rgba(172, 172, 172, 0.5);
  padding: 10px 20px;
  border-radius: 20px;
}

span.current {
  background: var(--primary-bg);
  padding: 10px 20px;
  border-radius: 50px;
  /* margin-top: 47px; */
  position: relative;
  top: 10px;
}

div#pagination-container a.page-link.next {
  background: rgba(172, 172, 172, 0.5);
  padding: 10px 20px;
  border-radius: 20px;
}

div#pagination-container li {
  margin-right: 20px;
  color: var(--primary-deep-dark) !important;
}

div#pagination-container ul li a {
  background: rgba(172, 172, 172, 0.5);
  padding: 10px 20px;
  border-radius: 20px;
}

.dropdown-menu-container .dropdown-item:hover {
  font-weight: 700 !important;
}

a.bkserch {
  text-transform: uppercase;
  font-weight: 700;
}

span.apply12 {
  font-weight: 700;
  box-shadow: 0px 4px 4px 0px #00000042;
}

a.bkserch {
  color: #5ba3c8;
  text-decoration: none !important;
}

/**********************/
input#search,
.search-row button.btn-primary.first-btn,
.search-row button.btn.btn-primary.second-btn {
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 31px rgb(0 0 0 / 5%);
}

.drop-for-serch-page {
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 31px rgb(0 0 0 / 5%);
}

/**************** popup css ******************/
.modal-dialog-full-width {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;
}

.modal-content-full-width {
  height: auto !important;
  min-height: 100% !important;
  border-radius: 0 !important;
  background-color: #ececec !important;
}

.modal-header-full-width {
  border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width {
  border-top: 1px solid #9ea2a2 !important;
}

html {
  scroll-behavior: smooth;
}

div#exampleModalPreview {
  padding-left: 20%;
}

.search-row button.btn.btn-primary.dropdown-toggle:focus {
  background: var(--primary-light) !important;
}

.navbar {
  box-shadow: none;
}

.search-button-css:hover {
  background: #1b7a9f;
  transition: all ease-in-out 0.5s;
}

p.text-after-position {
  margin-bottom: 50px;
}

.list-items ul li {
  list-style: none;
  position: relative;
}

.list-items ul li:before {
  content: "\2022";
  color: var(--primary-bg);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 33px;
  position: absolute;
  margin-left: -1em;
}

.nav-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

/* navbar */
.navbar-block {
  padding: 0px 100px;
}

.navbar-expand-lg .navbar-jobs-block.navbar-collapse {
  padding: 0px 50px;
  flex-grow: unset;
  width: 100%;
  flex-basis: unset;
}

.navbar-inner.navbar-collapse {
  flex-grow: unset;
  flex-basis: unset;
}

.navbar-nav .dropdown-menu {
  right: auto;
}
.nav-drop-menu .dropdown-menu a {
  border-left: 12px solid #fff;
  border-right: 12px solid #fff;
}
.nav-drop-menu .dropdown-menu a:hover {
  border-left: 12px solid #fff;
  border-right: 0px solid #fff;
}

.allow-email-container {
  width: 84%;
  text-align: left;
  margin-left: 55px;
  display: block;
  margin-top: 20px;
  padding: 10px 14px;
}

.distance-container {
  display: flex;
  align-items: center;
}

@media (max-width: 993px) {
  .search-button-css {
    border-radius: 0;
    border-radius: 6px;
  }
  .navbar {
    box-shadow: none;
    padding: 0;
  }

  .lbl-nav-signup {
    display: none;
  }
}

@media (max-width: 1600px) {
  .box-items {
    padding: 32px 0px 42px 32px;
  }
}

@media (max-width: 1500px) and (min-width: 1024px) {
  .box-items {
    padding: 12px 0px 22px 18px;
  }
  .box-items img {
    margin-bottom: 20px;
  }
}

@media (max-width: 1400px) {
  .navbar-inner .navbar-nav span {
    padding: 10px 10px !important;
  }
}

/* @media (max-width: 1200px) {
  .position-serve-second-section {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
} */

@media screen and (max-width: 1044px) and (min-width: 992px) {
  .navbar-inner .navbar-nav span {
    padding: 10px 9px !important;
  }

  .search-button-css {
    padding: 10px 14px !important;
    font-size: 16px;
    line-height: 19px;
  }

  .navbar-brand {
    margin-right: 0;
  }
}

@media (max-width: 1201px) and (min-width: 992px) {
  div#navbarNavAltMarkup a {
    margin-right: 2px;
    color: var(--primary-deep-dark);
    font-size: 16px;
  }

  div#navbarNavAltMarkup span {
    margin-right: 2px;
    color: var(--primary-deep-dark);
    font-size: 16px;
  }

  .job-seekers {
    margin-left: 8px;
  }
}

@media (min-width: 990px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    padding-bottom: 0px;
    padding-top: 0px;
    border: navajowhite;
    box-shadow: 0px 0px 25px -5px rgba(196, 196, 196, 0.4);
  }

  .navbar-expand-lg.navbar {
    padding: 8px 0px;
  }
}

@media (min-width: 991px) and (max-width: 1114px) {
  .navbar-expand-lg .navbar-jobs-block.navbar-collapse {
    padding: 0px 8px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1006px) {
  .navbar-expand-lg .navbar-jobs-block.navbar-collapse {
    padding: 0px 0px;
}
}

@media (max-width: 991px) {
  .navbar-manage {
    padding: 0 !important;
  }
  .user-toggle {
    width: auto;
  }
  .navbar-inner .navbar-nav a {
    border-top: 1px solid var(--dark-grayish);
  }

  .navbar-inner .navbar-nav a img {
    padding-bottom: 19px;
  }

  .navbar-inner .navbar-nav span {
    /* border-top: 1px solid var(--dark-grayish); */
  }

  .navbar-inner .navbar-nav span img {
    padding-bottom: 19px;
  }

  section.navbar-box-shdows {
    box-shadow: 0px 0px 60px rgb(0 0 0 / 11%);
  }

  div#navbarNavAltMarkup a {
    font-size: 16px;
  }

  div#navbarNavAltMarkup span {
    font-size: 16px;
    font-weight: 500;
  }

  .dropdown-user-icon {
    padding-top: 0px;
  }

  .dropdown-menu-container {
    width: 258px;
    height: auto;
    border-radius: 5px;
    padding: 20px 0px;
  }

  .dropdown-item-divider {
    width: 210px;
    margin-bottom: 5px;
  }

  .dropdown-items {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #3a3a3a;
  }

  .dropdown-user-icon {
    height: 30px;
    width: 30px;
    padding-top: 0px;
    line-height: 26px;
  }

  .user-icon {
    height: 35px;
    width: 35px;
  }

  .job-seekers {
    display: none !important;
  }

  .header-portal-col {
    position: absolute;
    top: -5px;
    right: 86px;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    right: 0;
  }

  .nonauth-dropdown-menu-container {
    width: 258px;
    height: auto;
    border-radius: 5px;
    padding: 20px 0px;
    transform: translate(-229px, 59px) !important;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 21%);
  }

  .nav-search-btn {
    background-color: #0351C7;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    padding: 10px 8px !important;
  }

  .sm-dropdown-username {
    max-width: 58%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
  }
}

@media screen and (max-width: 374px) {
  .nav-search-btn {
    display: none;
  }
}

@media only screen and (max-width: 991px) and (min-width: 767px) {
  body span.navbar-toggler-icon {
    margin-top: -5px;
  }
}

@media (max-width: 1200px) {
  .navbar-block {
    padding: 0px 5px;
  }

  .navbar-jobs-block {
    padding: 0 0px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 767px) {
  .navbar-nav {
    padding-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 769px) {
  .our-mission-section {
    padding-top: 0;
  }

  .our-mission {
    padding-top: 20px;
  }
}

@media (min-width: 769px) {
  .drop-down-list-for-emplyee .show a {
    font-size: 18px !important;
    border-bottom: 1px solid #c4c4c4;
  }

  .leftpadding {
    padding-left: 15px;
  }

  .getintouch-row {
    margin-left: 0;
    margin-right: 0;
    left: 0;
    box-shadow: none;
    padding: 0px !important;
    margin-top: 39px;
  }

  .getintouch-row:before {
    display: none;
  }

  .getintouch-row:after {
    display: none;
  }

  .getintouch-section {
    margin-top: 0;
    /*margin-bottom: 150px;*/
    border-top: 20px solid var(--primary-light-green);
  }

  .getintouch-form .form-group input {
    height: 50px;
  }
}

@media (max-width: 768px) {
  p.heading-title,
  p.location-manage,
  .dec {
    padding: 10px 20px;
  }

  .dec {
    -webkit-line-clamp: 10;
  }

  .manage-left-right-margin21 {
    margin-left: 20px;
    margin-right: 20px;
  }

  nav.navbar.navbar.navbar-inverse.navbar-fixed-top.navbar-expand-lg {
    position: absolute;
    display: flex;
    background: var(--primary-light);
    width: 100%;
    margin: 0px;
    box-shadow: 0px 0px 60px rgb(0 0 0 / 11%);
    margin-top: -11px;
    padding-top: 20px;
    padding-bottom: 11px;
  }

  .hospitality-row .col-md-10 {
    max-width: 100% !important;
    flex: 100%;
    padding: 0;
  }

  .dropdown-menu.sub-drop a {
    white-space: break-spaces;
    font-size: 16px !important;
    /*padding  : 9px 0px 0px 5px !important;*/
  }

  .nav-item {
    border-top: 1px solid #00000059;
  }

  button.btn.btn-primary.contact-btn:hover {
    background: #0069d9;
  }

  button.btn.btn-primary.contact-btn:active {
    color: var(--primary-light);
    background-color: #0062cc;
    border-color: #005cbf;
  }

  /*body p{margin-top: 15px;}*/
  img.img-thumbnail.job {
    margin-top: 0px;
  }

  .col-sm-12.faq {
    padding: 0px !important;
    margin-top: 0px;
  }

  .card-header {
    padding: 0px !important;
  }

  .postjob-accordion .card-body {
    padding: 10px !important;
  }

  .col-md-6.col-sm-12.image-restaurant-col.justify-content-center.align-self-center.head-img {
    padding: 0px !important;
    margin-left: -15px !important;
  }

  button.mobile-view {
    display: none;
  }

  .text-apron-right-side-img {
    display: none;
  }

  .spaces.head-padding {
    padding: 78px 14px 30px 14px;
  }

  .send-resume.text-center {
    margin: 0px;
  }

  .search-row label {
    font-size: 16px;
  }

  .dropdown.btn-space.show {
    margin-left: 30px;
  }

  div#pagination-container ul {
    padding-left: 0px;
  }

  div#pagination-container li {
    margin-right: 5px;
    color: var(--primary-deep-dark) !important;
  }

  .dec {
    margin-bottom: 20px;
  }

  .list-items ul {
    margin-bottom: 0px;
  }

  .manage-last-image-mobile {
    width: 80px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .nw-brder {
    width: 90% !important;
    text-align: center;
    margin: 0 auto;
  }

  .pos-mb {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .row.restaurant-row {
    padding-bottom: 10px;
  }

  .leftpadding {
    padding-left: 0x;
  }

  .user-toggle {
    width: auto;
  }

  .header-portal-col {
    position: absolute;
    top: -7px;
    right: 38px;
  }

  .mobile-view {
    font-size: 16px !important;
  }
}

@media (max-width: 767px) {
  .footer-section .row {
    display: grid !important;
  }

  .cnt {
    order: 1;
  }

  .address {
    order: 2;
  }

  .policys {
    order: 3;
  }

  .copyright {
    order: 4;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: none;
  }

  div#navbarNavAltMarkup a {
    margin-right: 0px;
  }

  div#navbarNavAltMarkup span {
    margin-right: 6px;
  }

  nav.navbar.navbar-expand-lg.navbar-light {
    position: absolute;
    display: flex;
    background: var(--primary-light);
    width: 100%;
    margin: 0px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.11);
    padding-top: 10px;
    z-index: 999;
  }

  .navbar-nav {
    margin-top: 10px;
  }

  section.navbar-box-shdows {
    padding-bottom: 0px;
  }


  .dropdown-menu.show a {
    border-top: 0px;
  }

  h2 {
    font-size: 24px !important;
    line-height: 36px !important;
  }

  body p {
    font-size: 14px;
  }

  .desktop-view {
    display: none;
  }

  button.desktop-view {
    display: none;
  }

  .we-help-text {
    margin-top: 30px;
  }

  body h3 {
    font-size: 20px;
  }

  section.lorem-ipsum-section {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  .image-lorem-col h3,
  .image-lorem-col h2 {
    padding-left: 15px;
  }

  .image-lorem-col {
    padding-right: 0px !important;
  }

  .image-lorem-col img {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .box-items-first {
    border-top: none;
    margin-top: 20px;
  }

  .box-items {
    padding: 58px 41px 67px;
  }

  .height {
    display: none;
  }

  .box-items img {
    margin-bottom: 15px;
  }

  .text-apron-right-side-img {
    display: none;
  }

  section.our-specialist {
    padding-bottom: 40px;
  }

  .box-items p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {

  .dropdown-inner-user-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 993px) {
  .nav-search-btn {
    width: auto;
  }
}

@media (max-width: 600px) {
  .mission-row {
    border-top: none;
  }

  .our-mission {
    border-top: 15px solid var(--primary-light-green);
    padding: 50px;
  }

  .mission-row .mobile-view {
    display: block;
  }

  .mission-row .desktop-view {
    display: none;
  }

  .our-mission-section {
    padding-top: 0;
  }

  .about-img {
    padding-left: 0 !important;
  }

  .about-img img {
    padding: 0 !important;
  }

  .about-img .we-help-text {
    padding: 0px 15px;
  }

  .about-img button.about-contact-btn {
    margin-left: 15px;
  }

  .about-img .contact-btn.mobile-view {
    margin-left: 15px;
  }

  .mission-image {
    padding: 0px !important;
  }

  .contact-btn {
    font-size: 16px;
    line-height: 25px;
  }
}
@media screen and (max-width: 425px) and (min-width: 320px) {
  .from-up-label {
    font-size: 18px;
    line-height: 20px;
    font-family: var(--secondary-font-family);
    font-weight: var(--semi-bold-font-weight);
  }
}

@media (max-width: 425px) {
  .dec {
    -webkit-line-clamp: 6 !important;
  }

  .allow-email-container {
    width: 100%;
    text-align: left;
    margin-left: 4px;
    padding: 0px 8px;
  }
}

@media (max-width: 375px) {
  .right-state {
    max-width: 50%;
  }

  .left-city {
    max-width: 50%;
  }

  .state {
    display: flex;
  }
}
